import React from 'react';
import { WMSTileLayer, LayersControl, Polyline, useMap } from "react-leaflet";
import GuttaBundle from './GuttaBundle';
import { getDVTAllBundles, getEastWestBundles } from 'static/readData';
import RouteMarkers from './RouteMarkers';
import { Container } from 'reactstrap';
import Legend from "./Legend.js";

const mapserverUrl = "https://ws-test.cmcc-opa.eu/cgi-bin/mapserv?";
const mapfileStaticPath = "/srv/ws/mapfiles/frame-visir/AdriaticSea_nu04_inv012_T07_";

function GuttaWMSLayer(props) {

  const map = useMap();

  return props.layers.map((lName, index) => (
    <>
      <WMSTileLayer
        layers={lName[0]}
        version="1.3.0"
        format="image/png"
        transparent="true"
        srs="EPSG:3857"
        map={mapfileStaticPath + props.path + "_" + props.date + "_" + props.time + "_" + lName[1] + "_" + props.vesselName + ".map"}
        url={mapserverUrl}
      />
      <Legend map={map} mapfile={mapfileStaticPath + props.path + "_" + props.date + "_" + props.time + "_" + lName[1] + "_" + props.vesselName + ".map"} layer={lName[0]} vessel={props.vessel}/>
    </>
  ))
}

class GuttaRoute extends React.PureComponent {

  constructor(props) {
    super(props);
    this.childKey = 0;
    this.state = {
      distRoute: [], markersDistRoute: [], timeRoute: [], markersTimeRoute: [],
      timeOrCo2Route: [], markersTimeOrCo2Route: [], bundlesRoute: [], removePolylinesVar: [],
      nectdfFields: null, isolines: null
    }
  }

  componentDidMount() {
    //console.log(this.props);
    if (this.props.product === "a") {
      const voyageArr = this.props.voyageArr;
      this.setState({
        // co2Route: voyageArr.co2Route, markersco2Route: voyageArr.markersco2Route, distRoute: voyageArr.distRoute,
        // markersDistRoute: voyageArr.markersDistRoute, timeRoute: voyageArr.timeRoute, markersTimeRoute: voyageArr.markersTimeRoute
        timeOrCo2Route: voyageArr.timeOrCo2Route, markersTimeOrCo2Route: voyageArr.markersTimeOrCo2Route, 
        distRoute: voyageArr.distRoute, markersDistRoute: voyageArr.markersDistRoute
      })
    }
    else if (this.props.product === "f") {
      getDVTAllBundles(this.props.path, this.props.product, this.props.vessel)
        .then(res => {
          if(res)
            this.setState({bundlesRoute: res })
          else{
            alert("Failed to retrieve bundle routes. Please try selecting different ports or try again later.");
          }
          if (this.props.mapRef && this.props.mapRef.current !== null){
            this.props.mapRef.current.fireEvent('dataload');
            //console.log("bundle load")
          }
        }).catch(function (error) {
          // console.log(error);
        });

    }
    else if (this.props.product === "AllF") {

      //West to east = east bound routes
      if (this.props.path.split("_")[1] === "EAST" && (this.state.bundlesRoute.length === 0 || this.state.bundlesRoute[0].length === 2)) {
        getEastWestBundles("east", this.props.leastSelection)
          .then(res => {
            this.setState({bundlesRoute: res })
            if (this.props.mapRef && this.props.mapRef.current !== null){
              this.props.mapRef.current.fireEvent('dataload');
              //console.log("west bundle load")
            }
          }).catch(function (error) {
            // console.log(error);
          });
      }
      //east to west = west bound routes
      else if (this.props.path.split("_")[1] === "WEST" && (this.state.bundlesRoute.length === 0 || this.state.bundlesRoute[0].length === 2)) {
        getEastWestBundles("west", this.props.leastSelection)
          .then(res => {
            this.setState({bundlesRoute: res })
            if (this.props.mapRef && this.props.mapRef.current !== null){
              this.props.mapRef.current.fireEvent('dataload');
              //console.log("east bundle load")
            }
          }).catch(function (error) {
            // console.log(error);
          });
      }
    }
  }
  componentWillReceiveProps(nextProps, prevState) {
    // if (nextProps.path !== this.props.path || nextProps.product !== this.props.product) {
    //   this.setState(
    //     //  this.state.removePolylinesVar[0] = <RemovePolylines />
    //   )
    // } else {
    //   this.setState(
    //     // this.state.removePolylinesVar[0] = []
    //   )
    // }

    // Object.entries(this.props).forEach(([key, val]) =>
    //   nextProps[key] !== val && console.log(`Prop '${key}' changed`)
    // );
  // if (this.state) {
  //   Object.entries(this.state).forEach(([key, val]) =>
  //     prevState[key] !== val && console.log(`State '${key}' changed`)
  //   );
  // }
    
    //if (this.props !== nextProps && nextProps.mapRef && nextProps.mapRef.current !== null) {
      //nextProps.mapRef.current.fireEvent('dataloading');
      //console.log("props change")
      //UNCOMMENT HERE TO CHECK REMOVE POLYLINES
      // console.log(this.props.mapRef)
      // const map = nextProps.mapRef.current;
      // for (var i in map._layers) {
      //   if(map._layers[i]._path) {
      //     try {
      //       map.removeLayer(map._layers[i]);
      //     }
      //     catch (e) {
      //       console.log("problem with " + e + map._layers[i]);
      //     }
      //   }
      // }
    //}

    if (nextProps.product === "a") {
      const voyageArr = nextProps.voyageArr;
      this.setState({
        // co2Route: voyageArr.co2Route, markersco2Route: voyageArr.markersco2Route, distRoute: voyageArr.distRoute,
        // markersDistRoute: voyageArr.markersDistRoute, timeRoute: voyageArr.timeRoute, markersTimeRoute: voyageArr.markersTimeRoute
        timeOrCo2Route: voyageArr.timeOrCo2Route, markersTimeOrCo2Route: voyageArr.markersTimeOrCo2Route, 
        distRoute: voyageArr.distRoute, markersDistRoute: voyageArr.markersDistRoute,
      })
    }
    //DVT All
    else if (nextProps.product === "f" && (nextProps.path !== this.props.path || nextProps.vessel !== this.props.vessel || this.state.bundlesRoute.length === 0 || this.state.bundlesRoute[0].length === 3)) {
      this.setState({ bundlesRoute: [] });
      getDVTAllBundles(nextProps.path, nextProps.product, nextProps.vessel)
        .then(res => {
          if(res)
            this.setState({bundlesRoute: res })
          else{
            alert("Failed to retrieve bundle routes. Please try selecting different ports or try again later.");
          }
          if (this.props.mapRef && this.props.mapRef.current !== null){
            this.props.mapRef.current.fireEvent('dataload');
            //console.log("next bundle load")
          }
        }).catch(function (error) {
          //  console.log(error);
        });
    }
    //DVP ALL
    else if (nextProps.product === "AllF") {
      if (!nextProps.selectedBundle) {        
        //West to east = east bound routes
        if (nextProps.path.split("_")[1] === "EAST"  && (nextProps.path.split("_")[0] !== this.props.path.split("_")[0] || nextProps.leastSelection !== this.props.leastSelection || this.state.bundlesRoute.length === 0 || this.state.bundlesRoute[0].length === 2)) {
          this.setState({ bundlesRoute: [] });
          getEastWestBundles("east", nextProps.leastSelection)
            .then(res => {
              this.setState({ bundlesRoute: res })
              if (this.props.mapRef && this.props.mapRef.current !== null){
                this.props.mapRef.current.fireEvent('dataload');
                //console.log("next east bundle load")
              }
            }).catch(function (error) {
              // console.log(error);
            });
        }
        //east to west = west bound routes
        else if (nextProps.path.split("_")[1] === "WEST"  && (nextProps.path.split("_")[0] !== this.props.path.split("_")[0] || nextProps.leastSelection !== this.props.leastSelection || this.state.bundlesRoute.length === 0 || this.state.bundlesRoute[0].length === 2)) {
          this.setState({ bundlesRoute: [] });
          getEastWestBundles("west", nextProps.leastSelection)
            .then(res => {
              this.setState({ bundlesRoute: res })
              if (this.props.mapRef && this.props.mapRef.current !== null){
                this.props.mapRef.current.fireEvent('dataload');
                //console.log("next west bundle load")
              }
            }).catch(function (error) {
              // console.log(error);
            });
        }
      }
    }
  }

  render() {
    ++this.childKey;
    const distLineOptions = { color: 'blue' };
    //const timeLineOptions = { color: 'red' };
    var timeOrCo2LineOptions = { color: 'green' }
    if(this.props.vessel === "S")
       timeOrCo2LineOptions = { color: 'red' };
    const { distRoute } = this.state;
   // const { timeRoute } = this.state;
    const { timeOrCo2Route } = this.state;
    const { markersDistRoute } = this.state;
   // const { markersTimeRoute } = this.state;
    const { markersTimeOrCo2Route } = this.state;
    const { bundlesRoute } = this.state;
    const nectdfFields = this.state;

    //console.log(bundlesRoute)

    if (!(this.props.path.includes("from")) && !(this.props.path.includes("to")) && !(this.props.startTime) && bundlesRoute && bundlesRoute.length === 0) {
      if (this.props.mapRef.current !== null) {
        this.props.mapRef.current.fireEvent('dataloading');
        //console.log("guttabundle")
      }
    }

    //TODO: WMS layer options in top right
    if (this.props.layer === "Sea currents") {
      nectdfFields[0] = <LayersControl position="topright">
        <LayersControl.Overlay checked name={["Currents"]}>
          <GuttaWMSLayer layers={[["curr", "curr"], ["currdir", "curr"], ["iso_time", "time"]]} path={this.props.path} date={this.props.startDate} time={this.props.startTime} engine={this.props.engine} vesselName={this.props.vesselName} vessel={this.props.vessel}/>
        </LayersControl.Overlay>
      </LayersControl>
    }
    else if (this.props.layer === "Waves") {
      nectdfFields[0] = <LayersControl position="topright">
        <LayersControl.Overlay checked name="Waves">
          <GuttaWMSLayer layers={[["wave", "wave"], ["wavedir", "wave"], ["iso_time", "time"]]} path={this.props.path} date={this.props.startDate} time={this.props.startTime} engine={this.props.engine} vesselName={this.props.vesselName} vessel={this.props.vessel}/>
        </LayersControl.Overlay>
      </LayersControl>
    }
    else if (this.props.layer === "Winds") {
      nectdfFields[0] = <LayersControl position="topright">
        <LayersControl.Overlay checked name="Winds">
          <GuttaWMSLayer layers={[["wind", "wind"], ["winddir", "wind"], ["iso_time", "time"]]} path={this.props.path} date={this.props.startDate} time={this.props.startTime} engine={this.props.engine} vesselName={this.props.vesselName} vessel={this.props.vessel}/>
        </LayersControl.Overlay>
      </LayersControl>
    }
    else {
      nectdfFields[0] = <LayersControl position="topright">
        <LayersControl.Overlay checked name={["Isolines"]}>
          {this.props.vessel === "S" 
          ? <GuttaWMSLayer layers={[["iso_dist", "dist"], ["iso_time", "time"]]} path={this.props.path} date={this.props.startDate} time={this.props.startTime} engine={this.props.engine} vesselName={this.props.vesselName} vessel={this.props.vessel}/>
          : <GuttaWMSLayer layers={[["iso_dist", "dist"], ["iso_CO2t", "CO2t"]]} path={this.props.path} date={this.props.startDate} time={this.props.startTime} engine={this.props.engine} vesselName={this.props.vesselName} vessel={this.props.vessel}/>}
        </LayersControl.Overlay>
      </LayersControl>
    }

    return (
      <div key={this.childKey}>
        {/* {removePolylinesVar[0]} */}

        {(this.props.product === "a") ?
          <Container>
            <Polyline pathOptions={distLineOptions} positions={distRoute} />
            {/* <Polyline pathOptions={timeLineOptions} positions={timeRoute} /> */}
            <Polyline pathOptions={timeOrCo2LineOptions} positions={timeOrCo2Route} />
            <RouteMarkers map={this.props.mapRef.current} routeMarkerClick={this.props.routeMarkerClick} pathOptions={distLineOptions} coords={markersDistRoute} selectedWP={this.props.selectedWP} type="Dist"></RouteMarkers>
            {/* <RouteMarkers map={this.props.mapRef.current} routeMarkerClick={this.props.routeMarkerClick} pathOptions={timeLineOptions} coords={markersTimeRoute} selectedWP={this.props.selectedWP} type="Time"> </RouteMarkers> */}
            <RouteMarkers map={this.props.mapRef.current} routeMarkerClick={this.props.routeMarkerClick} pathOptions={timeOrCo2LineOptions} coords={markersTimeOrCo2Route} selectedWP={this.props.selectedWP} type="CorT"></RouteMarkers>
          </Container>
          :
          (bundlesRoute.length === 0) ?
          <></>
          :
          <Container>
            <GuttaBundle bundle={bundlesRoute} routeBundleClick={this.props.routeBundleClick} selectedBundle={this.props.selectedBundle} vessel={this.props.vessel} leastSelection={this.props.leastSelection} selectedProd={this.props.product} convertBundleToRoute={this.props.convertBundleToRoute} />
          </Container>
        }

        {/* WMS layers based on map option selection*/}
        {nectdfFields[0]}

      </div>
    );
  }
}

export default GuttaRoute;