import React from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import YoutubeEmbed from "../static/YoutubeEmbed";
//import ReactGA from 'react-ga';

function About() {

  // React.useEffect(() => {
  //   ReactGA.initialize('G-G2K5KR7XGL'); 
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <>
      <div>
        <h1 className="text-white text-center text-lg-left display-3 header header-dark bg-info pb-6 pl-lg-8">
          ABOUT
        </h1>
        <Col className="pb-1 mt--5 pl-lg-8 pr-lg-8">
          <Card>
            <CardBody className="text-justify">
              <p>FRAME-VISIR is a decision support system for leisure boats in the Adriatic Sea. Its main purpose is to provide least-time routes for sailboats and least-CO<sub>2</sub> routes for motorboats, based on forecast meteo-marine conditions. <br /> <br />

                FRAME-VISIR is based on three main components:
                <ul>
                  <li> Environmental forecast fields (wave and sea-surface currents) from the <a href="https://marine.copernicus.eu/" target="_blank" rel="noreferrer">Copernicus Marine Service</a> and wind from the <a href="https://www.ecmwf.int/" target="_blank" rel="noreferrer">European Centre for Medium-Range Weather Forecasts</a>. </li>
                  <li> The VISIR-2 model in its operational version, deployed at the <a href="https://www.cmcc.it/super-computing-center-scc" target="_blank" rel="noreferrer">CMCC</a>’s High Performance Computing facility in Lecce (Italy). </li>
                  <li> This webservice for browsing the results. </li>
                </ul>
                The VISIR-2 model was first documented in <a href="https://www.mdpi.com/2077-1312/9/2/115" target="_blank" rel="noreferrer">this</a> paper. This model is based on a graph-search method working with dynamic edge weights. It keeps into account both coastline and bathymetry, enabling computation of optimal routes even in archipelagic domains such as coastal Croatia. Information and source code from the previous versions of VISIR versions can be found <a href="http://www.visir-model.net/" target="_blank" rel="noreferrer">here</a>.<br /><br />

                The vessel's sea-keeping and emissions are based on mathematical models evaluated by the <a href="http://www.diten.unige.it/index.php?lang=it" target="_blank" rel="noreferrer">University of Genoa</a>. The sailboat polar diagrams used were based on WinDesign VPP - a tool making use of hydrodynamic and aerodynamic models. The motorboat model is based on a first-order Boundary Element Method based on Rankine source distributions over the hull and the free surface.

                The time or CO<sub>2</sub> savings deriving from the VISIR optimal routes are computed for various routes, departure times, and engine loads. <br/><br/>

                FRAMESPORT is an Italy-Croatia strategic Interreg project, aiming at greener marine and coastal transport services between small ports of both Italy and Croatia. For more information about FRAMESPORT aims, partners, and funding, please visit  <a href="https://www.italy-croatia.eu/web/framesport" target="_blank" rel="noreferrer">https://www.italy-croatia.eu/web/framesport</a>
              </p>
            </CardBody>
          </Card>
          {/* <Card className="p-5">
            <CardTitle><h2 className="text-info">GUTTA project final video</h2></CardTitle>
            <YoutubeEmbed url = 'https://www.youtube.com/embed/JyEhrcsK19A'/>
          </Card> */}
        </Col>
      </div>
    </>
  );
}

export default About;