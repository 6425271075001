import { useEffect } from "react";
import L from "leaflet";

let div = null;
div = L.DomUtil.create("div", "info legend");
let divRoute = null;
divRoute = L.DomUtil.create("div", "info-route legend-route");
var distRouteLayer, timeRouteLayer, co2RouteLayer ;

function toggleRoute(map, id) {
  //console.log(distRouteLayer, timeRouteLayer, co2RouteLayer);
  //console.log(document.getElementById(id))
  if (document.getElementById(id) !== null && document.getElementById(id).style["font-weight"] === "bold") {
    if (id === "tdDistance") {
      //console.log("dist")
      for (var a in distRouteLayer) {
        map.addLayer(distRouteLayer[a]);
      }
      for (var b in timeRouteLayer) {
        map.removeLayer(timeRouteLayer[b]);
      }
      for (var c in co2RouteLayer) {
        map.removeLayer(co2RouteLayer[c]);
      }
    }
    else if (id === "tdTime") {
      for (var d in distRouteLayer) {
        map.removeLayer(distRouteLayer[d]);
      }
      for (var e in timeRouteLayer) {
        map.addLayer(timeRouteLayer[e]);
      }
      for (var f in co2RouteLayer) {
        map.removeLayer(co2RouteLayer[f]);
      }
    }
    else if (id === "tdCO2") {
      for (var g in distRouteLayer) {
        map.removeLayer(distRouteLayer[g]);
      }
      for (var h in timeRouteLayer) {
        map.removeLayer(timeRouteLayer[h]);
      }
      for (var i in co2RouteLayer) {
        map.addLayer(co2RouteLayer[i]);
      }
    }
    else {
      for (var l in distRouteLayer) {
        map.addLayer(distRouteLayer[l]);
      }
      for (var m in timeRouteLayer) {
        map.addLayer(timeRouteLayer[m]);
      }
      for (var n in co2RouteLayer) {
        map.addLayer(co2RouteLayer[n]);
      }
    }
  }
  else {
    for (var o in distRouteLayer) {
      map.addLayer(distRouteLayer[o]);
    }
    for (var p in timeRouteLayer) {
      map.addLayer(timeRouteLayer[p]);
    }
    for (var q in co2RouteLayer) {
      map.addLayer(co2RouteLayer[q]);
    }
  }
}

function legendTdClick(map, e) {
  if (!e) e = window.event;                // Get the window event
  e.cancelBubble = true;                       // IE Stop propagation
  if (e.stopPropagation) e.stopPropagation();  // Other Broswers
  
  if (!e.isTrusted) return;
  
  const distDiv = document.getElementById("tdDistance");
  const timeDiv = document.getElementById("tdTime");
  const co2Div = document.getElementById("tdCO2");

  if (e.target.id === "tdDistance") {
    //console.log("you are in tdDistance");
    //console.log("and distDiv.style[font-weight] is -> ", distDiv.style["font-weight"]);
    if (distDiv.style["font-weight"] === "bold") {
      document.getElementById(e.target.id).style["font-weight"] = "normal";
    }
    else {
      if (timeDiv!==null && timeDiv.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "bold"
        timeDiv.style["font-weight"] = "normal";
      }
      if (co2Div!==null && co2Div.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "bold"
        co2Div.style["font-weight"] = "normal";
      }
      else {
        document.getElementById(e.target.id).style["font-weight"] = "bold";
      }
    }
  }
  else if (e.target.id === "tdTime") {
    if (distDiv.style["font-weight"] === "bold") {
      document.getElementById(e.target.id).style["font-weight"] = "bold"
      distDiv.style["font-weight"] = "normal";
    }
    else {
      if (timeDiv!==null && timeDiv.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "normal";
      }
      else if (co2Div!==null && co2Div.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "bold"
        co2Div.style["font-weight"] = "normal";
      }
      else {
        document.getElementById(e.target.id).style["font-weight"] = "bold";
      }
    }
  }
  else if (e.target.id === "tdCO2") {
    if (distDiv.style["font-weight"] === "bold") {
      document.getElementById(e.target.id).style["font-weight"] = "bold"
      distDiv.style["font-weight"] = "normal";
    }
    else {
      if (timeDiv!==null && timeDiv.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "bold"
        timeDiv.style["font-weight"] = "normal";
      }
      if (co2Div!==null && co2Div.style["font-weight"] === "bold") {
        document.getElementById(e.target.id).style["font-weight"] = "normal";
      }
      else {
        document.getElementById(e.target.id).style["font-weight"] = "bold";
      }
    }
  }

  toggleRoute(map, e.target.id);
};

function Legend({ map, mapfile, layer, vessel }) {
  var legend = null;
  useEffect(() => {
    distRouteLayer = [];
    timeRouteLayer = [];
    co2RouteLayer = [];
    //Store route layers
    for (var i in map._layers) {
      if (map._layers[i].options.color === "blue")
        distRouteLayer.push(map._layers[i]);
      else if (map._layers[i].options.color === "red")
        timeRouteLayer.push(map._layers[i]);
      else if (map._layers[i].options.color === "green")
        co2RouteLayer.push(map._layers[i]);
    }
    //Legend display
    if (map) {
      if (layer === "curr" || layer === "wave" || layer === "wind") {
        legend = L.control({ position: "bottomleft" });
        legend.onAdd = () => {
          var url = "https://ws-test.cmcc-opa.eu/cgi-bin/mapserv?map=" + mapfile + "&version=1.1.1&service=WMS&REQUEST=GetLegendGraphic&MODE=legend&sld_version=1.1.0&LAYER=" + layer + "&format=image/png&STYLE=default";
          div.innerHTML = '<object id="wms-legend" type="text/html" width="130" height="250" data=' + url + '>';
          return div;
        };
        legend.addTo(map);
      }
      else if (layer === "iso_dist" || mapfile.includes("undefined")) {
        legend = L.control({ position: "bottomleft" });
        legend.onAdd = () => {
          div.innerHTML = '</>'
          return div;
        };
        legend.addTo(map);
      }

      const legendRoute = L.control({ position: "bottomright" });
      legendRoute.onAdd = () => {
        //Sailboat
        if(vessel === "S")
          divRoute.innerHTML = '<div><table class="center-legend" cellspacing="0" cellpadding="0"><tr><td id="tdTime"><span class="dot time"></span> Time</sub> </td></tr><tr><a><td id="tdDistance"><span class="dot distance"></span> Dist </td></a></tr></table></div>';
        //Motorboat
        else
          divRoute.innerHTML = '<div><table class="center-legend" cellspacing="0" cellpadding="0"><tr><td id="tdCO2"><span class="dot co2"></span> CO<sub>2</sub> </td></tr><tr><a><td id="tdDistance"><span class="dot distance"></span> Dist </td></a></tr></table></div>';
        // adding EventListener to legend items
        if(!mapfile.includes("undefined")){
          for (var i = 0; i < 2; i++) {
            divRoute.querySelectorAll("td")[i].addEventListener("click", legendTdClick.bind(null, map), false);  
          } 
        }
        return divRoute;
      };

      legendRoute.addTo(map);

    }
  }, [map]);
  return null;
}

export default Legend;
