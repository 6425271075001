import React from "react";
// react library for routing
import {Button} from "reactstrap";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
} from "reactstrap";

import Submenu from "./Submenu";
import SubmenuActive from "./SubmenuActive";
import DVP from "./SubMenuValues/PortDVP";
import DVDT from "./SubMenuValues/DateDVDTimeDVT";
import DVV from "./SubMenuValues/VesselDVV";
import DVL from "./SubMenuValues/LinechartDVL";
import DVE from "./SubMenuValues/EngineDVE";
import DE from "./SubMenuValues/ExportDE";
import DVM from "./SubMenuValues/MapDVM";
import DVS from "./SubMenuValues/SailboatDVS";

function Sidebar(props) {

  //console.log("Sidebar props: ",props.dateSelected)

  const { clearSelection, toggleSidenav, sidenavOpen, logo,
    portSelected, setPortSelected, changePortSelectOptionHandler, swapPortSelect,
    timeSelected, setTimeSelected,
    dateSelected, setDateSelected,
    vesselSelected, changeVesselSelectOptionHandler, vesselName,
    mapSelected, changeMapSelectOptionHandler,
    sailTypeSelected, changeSailTypeSelectOptionHandler,
    engineSelected, changeEngineSelectOptionHandler,
    chartSelected, changeChartSelectOptionHandler,
    exportSelected, changeExportSelectOptionHandler,
    solutionJson } = props;
    
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  const getClearButton = () => {
    if(portSelected.fromValue !== "from")
      return ( <div className="navbar-heading">
                <span className="docs-normal"><Button id="btn" className="px-2 py-1 mt-md-1 mb-md-1" onClick={clearSelection}>Clear All</Button></span>
                <span className="docs-mini"></span> 
              </div>);
  }

  const scrollbarOptions = {suppressScrollX:true};

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner">
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <div className="ml-0">
          <div
            className={classnames("sidenav-toggler d-none d-xl-block", {
              active: sidenavOpen,
            })}
            onClick={toggleSidenav}
          >
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
              <i className="sidenav-toggler-line" />
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-inner">
        <Collapse navbar isOpen={true}>
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal mt-4">Visualization Settings
              {/* <Button style={{fontSize:9}} className="ml-2 p-0" onClick={clearSelection}>Clear x</Button> */}
            </span>
            <span className="docs-mini">V</span> {/*Add icon*/}
          </h6>
         {getClearButton()}
          <Nav className="mb-md-3" navbar>
            <SubmenuActive title="Route" icon="ni ni-square-pin text-info" items={<DVP selectedDVP={portSelected} setPortSelected={setPortSelected} changeSelectOptionHandler={changePortSelectOptionHandler} swapSelect={swapPortSelect}  />} />
            <Submenu title="Date and Time" icon="ni ni-calendar-grid-58 text-info" items={<DVDT selectedDVP={portSelected} selectedDVD={dateSelected} selectedDVT={timeSelected} selectedDVE={engineSelected} selectedDVV={vesselSelected} vesselName={vesselName} changeSelectDateOptionHandler={setDateSelected} changeSelectTimeOptionHandler={setTimeSelected} solutions={solutionJson}/>} />
            <Submenu title="Vessel Type" icon="ni ni-basket text-info" items={<DVV selectedDVP={portSelected} selectedDVT={timeSelected} selectedDVV={vesselSelected} changeSelectOptionHandler={changeVesselSelectOptionHandler}/>} />
            {vesselSelected && vesselSelected==="S"
              ?<Submenu title="Sailboat Type" icon="ni ni-compass-04 text-info" items={<DVS selectedDVP={portSelected} selectedDVT={timeSelected} selectedDVS={sailTypeSelected} changeSelectOptionHandler={changeSailTypeSelectOptionHandler} />} />
              :<Submenu title="Engine Load" icon="ni ni-compass-04 text-info" items={<DVE selectedDVP={portSelected} selectedDVT={timeSelected} selectedDVE={engineSelected} changeSelectOptionHandler={changeEngineSelectOptionHandler} />} />
            }
            {timeSelected && timeSelected !== "ALL" 
              ?<Submenu title="Map" icon="ni ni-map-big text-info" items={<DVM selectedDVP={portSelected} selectedDVV={vesselSelected} selectedDVT={timeSelected} selectedDVM={mapSelected} changeSelectOptionHandler={changeMapSelectOptionHandler} />} /> 
              :<></>}           
            <Submenu title="Linechart" icon="ni ni-sound-wave text-info" items={<DVL selectedDVP={portSelected} selectedDVT={timeSelected} selectedDVV={vesselSelected} selectedDVL={chartSelected} changeSelectOptionHandler={changeChartSelectOptionHandler} />} />
          </Nav>
          <hr className="my-3" /> {/* Divider */}
          <h6 className="navbar-heading p-0 text-muted">
            <span className="docs-normal">Export Settings</span>
            <span className="docs-mini">E</span> {/* Add icon */}
          </h6>
          <Nav className="mb-md-3" navbar>
            <Submenu title="Export Options" icon="ni ni-cloud-download-95 text-info" items={<DE selectedDVP={portSelected} selectedDVD={dateSelected} selectedDVT={timeSelected} selectedDVV={vesselSelected} selectedDVS={sailTypeSelected} selectedDVE={engineSelected} selectedDE={exportSelected} changeSelectOptionHandler={changeExportSelectOptionHandler} />} />
          </Nav>
          <hr className="my-3" /> {/* Divider */}
          <h5 className="navbar-heading p-0 text-muted text-center">
            <span className="docs-normal">FRAME-VISIR v2.1.1</span>
            <span className="docs-mini">FV</span> {/* Add icon */}
          </h5>
        </Collapse>
      </div>
    </div>
  );
  return (
    <Navbar
      className={
        "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left"
      }
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar options={scrollbarOptions}>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
