import React from "react";

const YoutubeEmbed = ({url}) => (
  <div className="video-responsive">
    <iframe
      position="relative"
      width="50%"
      height="370.5"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Tutorial Video"
    />
  </div>
);

export default YoutubeEmbed;